import classNames from "classnames";
import parse from "html-react-parser";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import { CollToActionProps } from "./types";

export const CollToAction = ({
  content,
  classWrapper,
  classButton,
  classContentWrapper,
  classDescription,
  classTitle,
  iconType,
  eventHandler,
}: CollToActionProps) => {
  // const icon = {
  //   arrow: <IoArrowForward />,
  //   arrowSquare: <FaArrowUpRightFromSquare />,
  // };

  return (
    <div className={classNames("py-10 md:py-16", classWrapper)}>
      <div className="container flex flex-col justify-between gap-4 md:flex-row">
        <div className={classNames("", classContentWrapper)}>
          <h3
            className={classNames(
              "mb-2 flex flex-col gap-4 text-site-text-20-24 md:mb-4 md:flex-row md:text-site-text-32-35",
              classTitle
            )}>
            <Image
              className={classNames("h-full max-h-[28px] w-auto", content.imageBrand && "hidden md:inline")}
              src="/images/logo/st_icon.svg"
              width={62}
              height={28}
              alt="Simple Therapy"
            />
            {content.imageBrand && (
              <Image
                className="inline h-full max-h-[33px] w-auto md:hidden"
                src={`/images/logo/${content.imageBrand}`}
                width={254}
                height={33}
                alt="Simple Therapy"
              />
            )}
            <span>{content.title}</span>
          </h3>
          <p
            className={classNames(
              "text-site-text-16-24 font-extralight md:text-site-text-18-27",
              classDescription
            )}>
            {parse(content.description)}
          </p>
        </div>
        {content.buttonLink ? (
          <Link
            href={content.buttonLink || "#"}
            aria-label={content.buttonText || "Simple Therapy Button"}
            className={classNames(
              "group flex h-[51px] min-w-[196px] items-center justify-center gap-2 self-center rounded-[48px] text-site-text-16-23 font-normal transition-colors duration-300",
              classButton
            )}>
            <span>{content.buttonText}</span>
            <i className="icon-arrowR relative right-0 flex self-center text-[16px] font-[200] transition-all duration-300 group-hover:-right-1"></i>
          </Link>
        ) : (
          <button
            onClick={eventHandler}
            aria-label={content.buttonText || "Simple Therapy Button"}
            className={classNames(
              "group flex h-[51px] min-w-[196px] items-center justify-center gap-2 self-center rounded-[48px] text-site-text-16-23 font-normal transition-colors duration-300",
              classButton
            )}>
            <span>{content.buttonText}</span>
            <i className="icon-arrowR relative right-0 flex self-center text-[16px] font-[200] transition-all duration-300 group-hover:-right-1"></i>
          </button>
        )}
      </div>
    </div>
  );
};
