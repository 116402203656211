import Image from "next/image";
import React, { use, useEffect } from "react";

import { FlipCardProps } from "./types";

export const FlipCard = ({ card }: FlipCardProps) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.isIntersecting ? entry.target.classList.add("flip") : entry.target.classList.remove("flip");
        });
      },
      { rootMargin: "-50% 0px -50% 0px", threshold: 0 }
    );

    const elements = document.querySelectorAll('[data-target="card-observe"]');
    elements.forEach((element) => observer.observe(element));
  }, []);

  return (
    <div className="card perspective group h-[437px] w-[411px]" data-target="card-observe">
      <div className="card__content sm:group-hover:rotate-y-180 relative h-full w-full duration-700">
        <div className="card__front absolute flex h-full w-full flex-col items-center justify-center rounded-[24px] bg-gray-7 px-6 pb-6 pt-[92px]">
          {card.face1.logo && (
            <div className="mb-6 h-[120px] w-[180px]">
              <Image
                src={`/images/site/home/${card.face1.logo}`}
                height={120}
                width={180}
                alt={card.face1.name}
                className="max-h-[120px] max-w-[180px] object-contain"
              />
            </div>
          )}
          <h2 className="mb-1 text-site-text-16-20 font-bold">{card.face1.name}</h2>
          <p className="mb-1 text-site-text-16-24 font-light">{card.face1.position}</p>
          <p
            className="text-center text-site-text-14-21 font-light"
            dangerouslySetInnerHTML={{ __html: card.face1.company }}
          />
        </div>
        <div className="card__back absolute flex h-full w-full flex-col justify-end rounded-[24px] bg-site-background-blue-1 px-6 pb-6">
          <p className="mb-4 text-site-text-56-67 !leading-[0] text-white">“</p>
          <p className="text-site-text-23-30 font-light text-white">{card.face2.testimonial}</p>
        </div>
      </div>
    </div>
  );
};
