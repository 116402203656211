import classNames from "classnames";
import Image from "next/image";
import React from "react";

import { GuideWorkingCardProps } from "./types";

export const GuideWorkingCard = ({ card, classIcon, classWrapper }: GuideWorkingCardProps) => {
  return (
    <div className={classNames("rounded-[24px] border-t-[4px] px-6 py-8 shadow-xl", classWrapper)}>
      <div
        className={classNames(
          "icon mb-6 flex h-16 w-16 items-center justify-center rounded-full p-3",
          classIcon
        )}>
        <Image
          src={`/images/site/home/${card.icon}`}
          alt={card.title}
          className="max-h-10 w-auto object-contain"
          height={40}
          width={40}
        />
      </div>
      <h3 className="mb-1 text-site-text-24-33 font-normal text-body-1">{card.title}</h3>
      <p className="text-site-text-18-27 font-extralight text-body-1">{card.description}</p>
    </div>
  );
};
