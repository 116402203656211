import classNames from "classnames";
import React from "react";

import { CounterItemComponent } from "./CounterItem";
import { CounterSectionProps } from "./types";

export const CounterSection = ({ items, classWrapper }: CounterSectionProps) => {
  return (
    <div className={classNames("py-7 md:py-14", classWrapper)}>
      <div className="container flex flex-wrap items-start justify-center gap-10 md:justify-between md:gap-4">
        {items.map((item, index) => (
          <CounterItemComponent item={item} key={index} />
        ))}
      </div>
    </div>
  );
};
