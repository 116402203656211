import DefaultLayout from "@/components/site/Layouts/DefaultLayout";
import HomePageComponent from "@/components/site/pages/Home";
import { getPageActiveBySlug } from "@/lib/db/page";
// import { getSettings } from "@/lib/db/settings";
import { PageActive } from "@/types/entities/page";
// import { Settings } from "@/types/entities/settings";
import { SEO } from "@/types/seo";
import { getPageSeoByLocale } from "@/utils/pagesUtils";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import type { GetServerSidePropsContext } from "next/types";
import React from "react";

export default function HomePage({
  seo,
}: React.HTMLAttributes<HTMLDivElement> & {
  seo: SEO;
}) {
  const { locale, asPath } = useRouter();

  const canonicalLocale = locale === "default" ? "" : `/${locale}`;
  const canonicalUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${canonicalLocale}${asPath}`;
  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <NextSeo
        nofollow={seo?.follow}
        noindex={seo?.index}
        title={`${seo?.title || "Home"}`}
        description={seo?.description || ""}
        openGraph={{
          title: seo?.title,
          description: seo?.description,
          images: [
            {
              url: seo?.image || "",
              width: 1200,
              height: 630,
              alt: seo?.title,
            },
          ],
        }}
        additionalMetaTags={[
          {
            property: "keywords",
            content: seo?.keywords,
          },
        ]}
      />
      <HomePageComponent />
    </>
  );
}
HomePage.getLayout = function getLayout(page: React.ReactNode) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export const getServerSideProps = async ({ req, res, locale }: GetServerSidePropsContext) => {
  // res.setHeader("Cache-Control", "public, s-maxage=10, stale-while-revalidate=59");
  const translations = await serverSideTranslations(locale || "en", ["common"]);
  const pageData: PageActive | null = await getPageActiveBySlug("", locale ?? "en");
  const seo = await getPageSeoByLocale(pageData, locale || "en", "Page");

  return {
    props: {
      seo: seo,
      ...translations,
    },
  };
};
