import { useIntersectionObserver } from "@/lib/hooks/useIntersectionObserver";
import classNames from "classnames";
import React from "react";

import { CounterSectionProps, CounterItem } from "./types";

export const CounterItemComponent = ({ item, classWrapper, classItemNum, classItemText }: CounterItem) => {
  const elementRef: any = useIntersectionObserver(
    () => {
      const span = elementRef.current?.querySelector("span[data-number]");
      if (span) {
        const targetNumber = parseFloat(span.getAttribute("data-number") || "0");
        animateNumber(span, targetNumber);
      }
    },
    { threshold: 0.1 }
  );

  const animateNumber = (element: HTMLElement, target: number) => {
    let start = 0;
    const duration = 3000;
    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = Math.min((timestamp - start) / duration, 1);
      const currentNumber = progress * target;
      element.textContent =
        target % 1 === 0 ? Math.floor(currentNumber).toString() : currentNumber.toFixed(1);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  return (
    <div ref={elementRef} className={classNames("flex flex-[1_1_300px] flex-col items-center")}>
      <h2
        className={classNames("text-center text-site-text-56-67 text-white md:mb-4 md:text-site-text-72-86")}>
        <span data-number={item.number}>0</span>
        <span>{item.unitOfMeasurement}</span>
      </h2>
      <p
        className={classNames(
          "max-w-[250px] text-center text-site-text-18-27 font-light text-white md:text-site-text-28-33"
        )}>
        {item.description}
      </p>
    </div>
  );
};
