import classNames from "classnames";
import Image from "next/image";
import React, { useEffect, useState } from "react";

import { ScrollableSectionsProps } from "./types";

export const ScrollableSections = ({ itemSection, container, title }: ScrollableSectionsProps) => {
  const [activeSection, setActiveSection] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (container.current) {
        const containerTop = container.current.offsetTop;
        const containerHeight = container.current.scrollHeight - window.innerHeight;
        const scrollPosition = window.scrollY - window.innerHeight / 8;
        if (scrollPosition < containerTop) {
          setActiveSection(0);
        } else {
          const scrollRatio = (scrollPosition - containerTop) / containerHeight;
          const activeIndex = Math.min(Math.floor(scrollRatio * itemSection.length), itemSection.length - 1);
          setActiveSection(activeIndex);
        }
      }
    };
    // const observer = new IntersectionObserver(
    //   (entries) => {
    //     entries.forEach((entry) => {
    //       // if (entry.isIntersecting) {
    //       //   // const sectionId = parseInt(entry.target.getAttribute("data-id") || "0");
    //       //   setActiveSection(parseInt(entry.target.getAttribute("data-id") || "0"));
    //       // }
    //       if (entry.isIntersecting) {
    //         const sectionId = parseInt(entry.target.getAttribute("data-id") || "0");

    //         setActiveSection((prevSection) => {
    //           if (sectionId !== prevSection) {
    //             return sectionId;
    //           }
    //           return prevSection;
    //         });
    //       }
    //     });
    //   },
    //   { rootMargin: "-30% 0px -30% 0px", threshold: 0.5 }
    // );
    // const elements = document.querySelectorAll('[data-target="observe-section"]');
    // elements.forEach((element) => observer.observe(element));
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [itemSection.length, container]);
  useEffect(() => {
    setFadeIn(true);
    const timeoutId = setTimeout(() => setFadeIn(false), 300);
    return () => clearTimeout(timeoutId);
  }, [activeSection]);
  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="sticky top-24 h-fit overflow-hidden bg-site-background-gray py-5">
          {/* <div className="sticky top-24">
          <h2
            className={classNames(
              "mb-2 bg-site-background-gray text-site-text-60-60 text-primary-1",
              fadeIn ? "fade-in-text" : ""
            )}>
            {itemSection[activeSection]?.title}
          </h2>
          <p className={classNames("block text-site-text-18-27 md:hidden", fadeIn ? "fade-in-text" : "")}>
            {itemSection[activeSection]?.description}
          </p>
          {itemSection[activeSection]?.image && (
            <Image
              src={`/images/site/home/${itemSection[activeSection]?.image}`}
              alt={itemSection[activeSection]?.title}
              width={418}
              height={418}
              className={classNames(
                "mt-[35px] hidden h-[418px] w-[418px] rounded-[41px] object-cover md:block"
              )}
            />
          )}
        </div> */}
          <div className="min-h-[100px] md:min-h-[600px]">
            <h2 className="bg-site-background-gray text-site-text-32-38">{title}</h2>
            <div className="relative">
              {itemSection.map((section, index) => (
                <div
                  key={index}
                  className={classNames(
                    "absolute top-0 transition-all duration-100",
                    activeSection === index ? "opacity-100" : "opacity-0"
                  )}>
                  <h2
                    className={classNames(
                      "mb-2 bg-site-background-gray text-site-text-60-60 text-primary-1"
                    )}>
                    {section?.title}
                  </h2>
                  <p className={classNames("hidden text-site-text-18-27", fadeIn ? "fade-in-text" : "")}>
                    {section?.description}
                  </p>
                  {section?.image && (
                    <Image
                      src={`/images/site/home/${section?.image}`}
                      alt={section?.title}
                      width={418}
                      height={418}
                      className={classNames(
                        "mt-[35px] hidden h-[418px] w-[418px] rounded-[41px] object-cover md:block"
                      )}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classNames("w-full")}>
          {itemSection.map((section, index) => (
            <div
              data-target="observe-section"
              data-title={section.title}
              data-id={index}
              key={index}
              className={classNames(
                "sticky top-[220px] bg-site-background-gray py-10 transition-all duration-300 md:relative md:top-0",
                itemSection.length - 1 >= index + 1 && activeSection === index
                  ? "border-b-[1px] border-b-gray-5"
                  : "",
                "section-" + index
              )}>
              <div
                className={classNames(
                  "transition-all duration-100",
                  activeSection === index ? "opacity-100" : "opacity-10"
                )}>
                <div
                  className={classNames(
                    "border-l-[4px] px-6",
                    activeSection === index ? "border-l-primary-1" : "border-l-transparent"
                  )}>
                  <div
                    className={classNames(
                      "mb-4 flex h-16 w-16 items-center justify-center rounded-[8px] p-2.5",
                      activeSection === index ? "bg-primary-1" : "bg-bodydark2"
                    )}>
                    <Image src={`/images/site/home/${section.icon}`} alt="icon" width={50} height={50} />
                  </div>
                  <h2 className="mb-2 text-site-text-24-33">{section.title}</h2>
                  <p className="text-site-text-18-27 font-light">{section.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
